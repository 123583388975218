import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My DnD group recently made the switch from fourth to fifth edition in our campaign that's been going on for a number of years now. Obviously there was a bit of an adjustment when making a switch like this, but we've run a few sessions now and I'd like to lay down my feelings on it.`}</p>
    <h4>{`Pro: Full character builder support`}</h4>
    <p>{`Without a doubt this is best part of the switch. Building a character is effortless. Leveling a character is effortless. The charactermancer knows just what happens at each level for each supported class and informs you of selections you need to make but otherwise handles everything else. This is actually how it `}<em parentName="p">{`used`}</em>{` to be for 4th edition. Before the release of the Dark Sun module, there was a Microsoft Silverlight application that did all of this for you for 4th edition characters. However because either Microsoft was no longer supporting Silverlight, or the math was changing for Dark Sun, or they just didn't want to convert the application to a web app (or WPF desktop application which would have been easy...I know from experience), the character creator got dumped and it made making a 4E character much more imposing.`}</p>
    <h4>{`Con: Race and Class no longer available`}</h4>
    <p>{`This one is mostly personal, but currently there is no built-in support for a Minotaur Runepriest. I had grown quite attached to my eager to charge (literally) into battle loveable moron of a runepriest. Now, I'm led to believe that the charactermancer supports `}<em parentName="p">{`some`}</em>{` of this, to some extent. But the further you deviate from the the basics, the more you're going to be leaning on pen and paper. This would be fine really for adding a race, but duplicating the runepriest would still require more work than I want to put in, even if there is something so `}<a parentName="p" {...{
        "href": "https://www.dandwiki.com/wiki/Aura_Swordsman_(5e_Class)"
      }}>{`very close`}</a>{`. In the end, I ended up switching to a Bugbear Paladin, after briefly trying the Fighter, which may just be the blandest class in the history of classes.`}</p>
    <h4>{`Con: Melee classes have boring design`}</h4>
    <p>{`This one is pretty universal. Here's the basic design philosophy behind them all: perform one interesting decision per turn (or combat) and grant them multiple attacks at some point (5th level). Here's a brief rundown of a few of the built-in melee classes.`}</p>
    <ul>
      <li parentName="ul">{`Rogue: Once per turn you can sneak attack if you have advantage or an ally is next to the enemy as well. Once per turn get a bonus action to disengage, dash or hide.`}</li>
      <li parentName="ul">{`Monk: Spend a Ki point to: have an extra attack, dodge, dash, disengage. Extra attacks at 5th level.`}</li>
      <li parentName="ul">{`Barbarian: Choose to Rage or not (which confers some benefits). Choose to attack Recklessly. Extra attacks at 5th level.`}</li>
      <li parentName="ul">{`Fighter: The most boring of all. Once per combat take an extra attack action. Once per combat heal yourself. Extra attacks at 5th level. `}</li>
    </ul>
    <p>{`So not only are the classes designed the same way, in the end nothing these classes does really amounts to more than hit with hammer/dagger/axe. `}</p>
    <p>{`Let's contrast this with what a fourth edition fighter can do at level 1 with just the original Player's Handbook using the standard two at-wills, one encounter, one daily.`}</p>
    <ul>
      <li parentName="ul">{`Cleave: Hit an enemy and an adjacent enemy for a bit of damage for free.`}</li>
      <li parentName="ul">{`Tide of Iron: Hit an enemy and push it one square, allowing you to occupy the square the enemy did if you want.`}</li>
      <li parentName="ul">{`Steel Serpent Strike: Hit an enemy, slowing it and preventing it from shifting (opening the door for opportunity attacks).`}</li>
      <li parentName="ul">{`Villain's Menace: Hit an enemy and gain bonuses to hit and damage until the end of the encounter.`}</li>
    </ul>
    <p>{`Already the attacks are more involved than anything I've seen in 5th edition, and if you want you can spend your action point (which every class has access to) and take another standard action. It does everything the fighter does in 5th edition and more at level 1.`}</p>
    <h4>{`Con: Not a fan of the magic system`}</h4>
    <p>{`If melee classes are boring, surely the casters must be better right? As mentioned above, I settled on the Paladin. As it's a hybrid class I get access to some cleric spells but I dislike the slot system. It reminds me too much of the original Final Fantasy's magic system where each level of magic had a certain number of times it can be cast. This is further complicated by my own class where the interesting melee decision I get to make is to expend a slot to increase my damage for the turn. So I truly get the worst of all the worlds here.`}</p>
    <h4>{`Con: No true tanking anymore`}</h4>
    <p>{`The concept of marking is gone so there's no way to incentivize enemies to attack the one person who can withstand the damage. Granted in our party's case, this was kind of a non-issue as we didn't have a real tank, but we had two tanky healers in the Runepriest and Warlord but when we converted to 5th I wanted to pick up a tank, but found to my chagrin that there really isn't one. Barbarians when raging take less damage, rogues and monks have ways to avoid or take less damage, and protection style paladins can impose disadvantage when enemies attack an adjacent ally with a reaction. So the only real option here is making `}<em parentName="p">{`yourself`}</em>{` more tanky.`}</p>
    <h4>{`Overall Impressions`}</h4>
    <p>{`Counting the pros and cons you can easily see that I'm not a fan of 5th edition. Most of the tactical aspect of combat is gone. All you have to do is look at those abilities for the 4E Fighter to see that positioning and debuffing was an important part of combat in that game, and while we've kept the grid for combat the combat system itself doesn't allow for the other good stuff from 4E combat. I can understand that people might have thought 4th edition combat was a little ponderous, and it could be if people weren't ready the time their turn rolled around but, as a fan of tactical RPGs, I loved it. Finally, by the time we were third or fourth level in 4th edition, my character felt like a god and now I feel like just another guy getting ready to hit someone my hammer twice on my turn.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      